import { Floor1, Floor2, Floor3, Floor4, Floor5 } from "./Content";
// import Games from "./Games";
import MySwiper from "./MySwiper";

export default function Inicio() {
  return (
    <>
      <MySwiper />
      <Floor1 />
      <Floor2 />
      <Floor3 />
      <Floor4 />
      <Floor5 />
    </>
  );
}
