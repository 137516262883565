// Codere StickyFooter Footer \\
import { Image } from "react-bootstrap";
function StickyFooter() {
  return (
    <div className="sticky-footer-contatiner">
      <a
        id="sticky-footer-deportes"
        href="https://m.caba.codere.bet.ar/deportes/#/HomePage"
        className="sticky-item"
        rel="nofollow"
      >
                    <Image
              src="https://www.codere.bet.ar/argentina/images/homepage/mobile/deportesIconStFooter.png"
              alt=""
              fluid
            />
        DEPORTES
      </a>
      <a
        id="sticky-footer-directo"
        href="https://m.caba.codere.bet.ar/deportes/#/DirectosPage"
        className="sticky-item"
        rel="nofollow"
      >
                    <Image
              src="https://www.codere.bet.ar/argentina/images/homepage/mobile/directoIconStFooter.png"
              alt=""
              fluid
            />
        DIRECTO
      </a>
      <a
        id="sticky-footer-casino"  
        href="https://m.caba.codere.bet.ar/deportes/#/CasinoPage"
        className="sticky-item"
        rel="nofollow"
      >
                    <Image
              src="https://www.codere.bet.ar/argentina/images/homepage/mobile/casinoIconStFooter.png"
              alt=""
              fluid
            />
        CASINO
      </a>
    </div>
  );
}

export default StickyFooter;
