import React from "react";

const PopUp = ({ title, links, sportctaurl, sportcta, onClose }) => {
  return (
    <div className="popup">
      <div className="popup-content">
        <h2>{title}</h2>
        <div className="popUpBtn">
          {links.map((link, index) => (
            <a key={index} href={link.url} className="button-link">
              {link.text}
            </a>
          ))}
        </div>
        {/*<div className="sportcta mt-3">*/}
        {/*  <a href={sportctaurl} className="sportctalink">*/}
        {/*    {sportcta}*/}
        {/*  </a>*/}
        {/*</div>*/}
        <button className="close-button" onClick={onClose}></button>
      </div>
    </div>
  );
};

export default PopUp;
