import { Zoom } from "react-awesome-reveal";
import { Container, Row, Col, Image, Button } from "react-bootstrap";
import React from "react";
import "../CSS/homePage.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import HomePageSVGIconsSport from "./HomePageSVGIconsSport";

function Floor1() {
  return (
    <>
      <Container className="mb-3">
        <Row className="floor px-0 py-2">
          <Col xs={12} md={6} className="d-block d-md-none ">
            <Image
              src="https://www.codere.bet.ar/argentina/images/homepage/desktop/RiverSponsorshipImage.png"
              alt="RiverSponsorshipImage"
              fluid
            />
          </Col>
          <Col xs={12} md={6} className="ps-3">
            <div className="text-block mt-2">
              <h2 className="mb-2 no-border-mobile text-center-mobile">
                <span>CASA DE APUESTAS OFICIAL</span>
              </h2>
              <div className="dots-line d-flex  mb-3 d-md-none">
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
              </div>

              <p className="mb-5 text-center-mobile text-mobile">
                ¡Bienvenido a la mejor casa de apuestas en línea de Argentina!
                Hace tiempo venimos disfrutando juntos de un mundo de
                entretenimientos y ahora te brindamos la posibilidad de que te
                diviertas de manera online.
              </p>
              <a
                className="homepage-button btn-mobile"
                href="#"
                id="btnBl_1"
                variant="primary"
              >
                 juega ahora
                  {/*<FontAwesomeIcon icon={faAngleRight} />*/}
              </a>
            </div>
          </Col>
          <Col xs={12} md={6} className="d-none d-md-block codere_pr-8px">
            <Image
              src="https://www.codere.bet.ar/argentina/images/homepage/desktop/RiverSponsorshipImage.png"
              alt="RiverSponsorshipImage"
              fluid
            />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export { Floor1 };

function Floor2() {
  return (
    <>
      <Container className=" floor mb-3">
        <Row>
          <Col xs={12} md={4}>
            <Row className=" px-0 py-2">
              <Col xs={6} md={6} className="mb-2">
                <Image
                  src="https://www.codere.bet.ar/argentina/images/homepage/desktop/jumangi.png"
                  alt="jumangi"
                  fluid
                />
              </Col>
              <Col xs={6} md={6} className="mb-2">
                <Image
                  src="https://www.codere.bet.ar/argentina/images/homepage/desktop/lightning.png"
                  alt="lightning"
                  fluid
                />
              </Col>
              <Col xs={6} md={6}>
                <Image
                  src="https://www.codere.bet.ar/argentina/images/homepage/desktop/divineFortune.png"
                  alt="divineFortune"
                  fluid
                />
              </Col>
              <Col xs={6} md={6}>
                <Image
                  src="https://www.codere.bet.ar/argentina/images/homepage/desktop/roulette.png"
                  alt="roulette"
                  fluid
                />
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={8} className="d-flex align-items-baseline">
            <div className="text-block mt-2">
              <h2 className="mb-4 codere_mb_mob_1 no-border-mobile text-center-mobile ">
                <span>NUEVOS JUEGOS DE CASINO</span>
              </h2>
              <div className="dots-line d-flex  mb-3 d-md-none">
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
              </div>
              <div className="mb-5">
                <div className="tatTitle mb-4 text-mobile text-center-mobile">
                  {" "}
                  ¡JUEGA SLOTS EN CODERE! LAS MEJORES MÁQUINAS TRAGAPERRAS DE
                  ESPAÑA{" "}
                </div>
                <p className=" text-center-mobile text-mobile">
                  El mejor Casino online del mundo te está esperando en Codere.
                  En él podrás encontrar las mejores slots del mercado con las
                  temáticas más divertidas del mundo. Ya no tendrás que
                  preocuparte por sacar tiempo para acudir a tu casino más
                  cercano ya que ahora podrás jugar a una gran variedad de las
                  conocidas como máquinas tragaperras desde cualquier
                  dispositivo (móvil, app de Codere o desde tu ordenador).
                </p>
              </div>
              <div className="hpBtnD">
              <a id= 'btnBl_2' className="homepage-button text-center-mobile btn-mobile" href="#" variant="primary">
                  juega ahora
                  {/*<FontAwesomeIcon icon={faAngleRight} />*/}
              </a>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export { Floor2 };

function Floor3() {
  return (
    <>
      <Container className="mb-3">
        <Row className="floor px-0 py-2">
          <Col xs={12} md={6}>
            <div className="text-block mt-2 ps-3">
            <Col xs={12} md={6} className="d-block mb-3 d-md-none ">
            <Image
              src="https://www.codere.bet.ar/argentina/images/homepage/desktop/RiverSponsorshipImage.png"
              alt="RiverSponsorshipImage"
              fluid
            />
          </Col>
              <h2 className="mb-4 codere_mb_mob_1 text-center-mobile no-border-mobile ">
                <span>TODOS LOS DEPORTES</span>
              </h2>
              <div className="dots-line d-flex  mb-4 d-md-none">
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
              </div>

<HomePageSVGIconsSport />

            <div className="hpBtnD">
              <a className="homepage-button btn-mobile text-center-mobile" id='btnBl_3' href="#" variant="primary">
                  ver mas
                  {/*<FontAwesomeIcon icon={faAngleRight} />*/}
              </a>
            </div>

            </div>
          </Col>
          <Col xs={12} md={6} className="d-none d-md-block">
            <Image
              src="https://www.codere.bet.ar/argentina/images/homepage/desktop/RiverSponsorshipImage.png"
              alt="RiverSponsorshipImage"
              fluid
            />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export { Floor3 };

function Floor4() {
  return (
    <>
      <Container className="mb-3">
        <Row className="floor px-0 py-2">
          <Col xs={12} md={6} className="d-block d-md-none">
            <Image
              src="https://www.codere.bet.ar/argentina/images/homepage/desktop/RiverSponsorshipImage.png"
              alt="RiverSponsorshipImage"
              fluid
            />
          </Col>
          <Col xs={12} md={6} className="d-none d-md-block">
            <Image
              src="https://www.codere.bet.ar/argentina/images/homepage/desktop/RiverSponsorshipImage.png"
              alt="RiverSponsorshipImage"
              fluid
            />
          </Col>
          <Col xs={12} md={6}>
            <div className="text-block  mt-2">
              <h2 className="mb-4 codere_mb_mob_1 no-border-mobile text-center-mobile">
                <span>CASINO EN VIVO</span>
              </h2>
              <div className="dots-line d-flex  mb-4 d-md-none">
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
                
              </div>
              <p className="mb-5 text-mobile text-center-mobile">
                ¡Bienvenido a la mejor casa de apuestas en línea de Argentina!
                Hace tiempo venimos disfrutando juntos de un mundo de
                entretenimientos y ahora te brindamos la posibilidad de que te
                diviertas de manera online.
              </p>
              <div className="hpBtnD">
              <a className="homepage-button btn-mobile text-center-mobile" href="#" id='btnBl_4' variant="primary">
                {" "}

                  Ver mas
                  {/*<FontAwesomeIcon icon={faAngleRight} />*/}

              </a>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export { Floor4 };

function Floor5() {
  return (
    <>
      <Container className=" floor mb-3">
        <Row>
          <Col md={12}>
            <Row className=" px-0 py-2">
              <h2 className="mb-4 codere_mb_mob_1 text-center no-border-mobile no-border">
                <span>TILES ROW</span>
              </h2>
              <div className="dots-line d-flex  mb-2 d-md-none">
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
              </div>
              </Row>
              <Row>
              <Col xs={6} md={3} className="mb-2">
                <Image
                  src="https://www.codere.bet.ar/argentina/images/homepage/desktop/jumangi.png"
                  alt="jumangi"
                  fluid
                />
              </Col>
              <Col xs={6} md={3} className="mb-2">
                <Image
                  src="https://www.codere.bet.ar/argentina/images/homepage/desktop/lightning.png"
                  alt="lightning"
                  fluid
                />
              </Col>
              <Col xs={6} md={3}>
                <Image
                  src="https://www.codere.bet.ar/argentina/images/homepage/desktop/divineFortune.png"
                  alt="divineFortune"
                  fluid
                />
              </Col>
              <Col xs={6} md={3}>
                <Image
                  src="https://www.codere.bet.ar/argentina/images/homepage/desktop/roulette.png"
                  alt="roulette"
                  fluid
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export { Floor5 };
