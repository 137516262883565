import React from "react";

const HomePageSVGIconsSport = () => {
	return (
		<div className="d-flex justify-content-around ">
			<div className="mb-2 col-md-3 col-sm-3 p-3 text-center">
				<a href="#"><img src="https://www.codere.bet.ar/argentina/images/homepage/desktop/Basketball.svg" alt="jumangi" className="img-fluid max-width-SVG"/></a>
				<span className="textUnderSVG">Basketball</span>
			</div>
			<div className="mb-2 col-md-3 col-sm-3 p-3 text-center">
				<a href="#"><img src="https://www.codere.bet.ar/argentina/images/homepage/desktop/Fighting.svg" alt="lightning" className="img-fluid max-width-SVG"/></a>
				<span className="textUnderSVG">Fighting</span>
			</div>
			<div className="mb-2 col-md-3 col-sm-3 p-3 text-center">
				<a href="#"><img src="https://www.codere.bet.ar/argentina/images/homepage/desktop/Football.svg" alt="divineFortune" className="img-fluid max-width-SVG"/></a>
				<span className="textUnderSVG">Football</span>
			</div>
			<div className="mb-2 col-md-3 col-sm-3 p-3 text-center">
				<a href="#"><img src="https://www.codere.bet.ar/argentina/images/homepage/desktop/Tennis.svg" alt="roulette" className="img-fluid max-width-SVG"/></a>
				<span className="textUnderSVG">Tennis</span>
			</div>
		</div>
	);
};

export default HomePageSVGIconsSport;
