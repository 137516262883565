export default [
	{
		trigger    : "Deportes",
		title      : "¿Desde dónde querés jugar?",
		sportcta   : (
			<>
				Ver más sobre Fútbol Argentino{" "}
				<span className="btnArrowRight">&rarr;</span>
			</>
		),
		sportctaurl:
			"https://www.bplay.bet.ar/deportes/futbol/eventos/liga-profesional/",
		text       : "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
		links      : [
			{url: "https://m.caba.codere.bet.ar/deportes/#/HomePage", text: "Caba"},
			{url: "https://example.com/2", text: "Buenos Aires+"},
		],
	},
	{
		trigger    : "Directo",
		title      : "¿Desde dónde querés jugar?",
		sportcta   : (
			<>
				Ver más sobre Fútbol Argentino{" "}
				<span className="btnArrowRight">&rarr;</span>
			</>
		),
		sportctaurl:
			"https://www.bplay.bet.ar/deportes/futbol/eventos/liga-profesional/",
		text       : "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
		links      : [
			{url: "https://m.caba.codere.bet.ar/deportes/#/HomePage", text: "Caba"},
			{url: "https://example.com/4", text: "Buenos Aires"},
		],
	},
	{
		trigger    : "Casino",
		title      : "¿Desde dónde querés jugar?",
		sportcta   : (
			<>
				Ver más sobre Fútbol Argentino{" "}
				<span className="btnArrowRight">&rarr;</span>
			</>
		),
		sportctaurl:
			"https://www.bplay.bet.ar/deportes/futbol/eventos/liga-profesional/",
		text       : "",
		links      : [
			{url: "https://m.caba.codere.bet.ar/deportes/#/HomePage", text: "Caba"},
			{url: "https://example.com/4", text: "Buenos Aires"},
		],
	},
	{
		trigger    : "Casinoenvivo",
		title      : "¿Desde dónde querés jugar?",
		sportcta   : (
			<>
				Ver más sobre Fútbol Argentino{" "}
				<span className="btnArrowRight">&rarr;</span>
			</>
		),
		sportctaurl:
			"https://www.bplay.bet.ar/deportes/futbol/eventos/liga-profesional/",
		text       : "",
		links      : [
			{url: "https://m.caba.codere.bet.ar/deportes/#/HomePage", text: "Caba"},
			{url: "https://example.com/4", text: "Buenos Aires"},
		],
	},
	{
		trigger    : "Promociones",
		title      : "¿Desde dónde querés jugar?",
		sportcta   : (
			<>
				Ver más sobre Fútbol Argentino{" "}
				<span className="btnArrowRight">&rarr;</span>
			</>
		),
		sportctaurl:
			"https://www.bplay.bet.ar/deportes/futbol/eventos/liga-profesional/",
		text       : "",
		links      : [
			{url: "https://m.caba.codere.bet.ar/deportes/#/HomePage", text: "Caba"},
			{url: "https://example.com/4", text: "Buenos Aires"},
		],
	},
	{
		trigger    : "NuestrasSalas",
		title      : "¿Desde dónde querés jugar?",
		sportcta   : (
			<>
				Ver más sobre Fútbol Argentino{" "}
				<span className="btnArrowRight">&rarr;</span>
			</>
		),
		sportctaurl:
			"https://www.bplay.bet.ar/deportes/futbol/eventos/liga-profesional/",
		text       : "",
		links      : [
			{url: "https://m.caba.codere.bet.ar/deportes/#/HomePage", text: "Caba"},
			{url: "https://example.com/4", text: "Buenos Aires"},
		],
	},
	{
		trigger    : "seocasino-en-vivo",
		title      : "¿Desde dónde querés jugar?",
		sportcta   : (
			<>
				Ver más sobre Fútbol Argentino{" "}
				<span className="btnArrowRight">&rarr;</span>
			</>
		),
		sportctaurl:
			"https://www.bplay.bet.ar/deportes/futbol/eventos/liga-profesional/",
		text       : "",
		links      : [
			{url: "https://m.caba.codere.bet.ar/deportes/#/HomePage", text: "Caba"},
			{url: "https://example.com/4", text: "Buenos Aires"},
		],
	},
	{
		trigger    : "seocasino",
		title      : "¿Desde dónde querés jugar?",
		sportcta   : (
			<>
				Ver más sobre Fútbol Argentino{" "}
				<span className="btnArrowRight">&rarr;</span>
			</>
		),
		sportctaurl:
			"https://www.bplay.bet.ar/deportes/futbol/eventos/liga-profesional/",
		text       : "",
		links      : [
			{url: "https://m.caba.codere.bet.ar/deportes/#/HomePage", text: "Caba"},
			{url: "https://example.com/4", text: "Buenos Aires"},
		],
	},
	{
		trigger    : "seoruleta-online",
		title      : "¿Desde dónde querés jugar?",
		sportcta   : (
			<>
				Ver más sobre Fútbol Argentino{" "}
				<span className="btnArrowRight">&rarr;</span>
			</>
		),
		sportctaurl:
			"https://www.bplay.bet.ar/deportes/futbol/eventos/liga-profesional/",
		text       : "",
		links      : [
			{url: "https://m.caba.codere.bet.ar/deportes/#/HomePage", text: "Caba"},
			{url: "https://example.com/4", text: "Buenos Aires"},
		],
	},
	{
		trigger    : "seoslots",
		title      : "¿Desde dónde querés jugar?",
		sportcta   : (
			<>
				Ver más sobre Fútbol Argentino{" "}
				<span className="btnArrowRight">&rarr;</span>
			</>
		),
		sportctaurl:
			"https://www.bplay.bet.ar/deportes/futbol/eventos/liga-profesional/",
		text       : "",
		links      : [
			{url: "https://m.caba.codere.bet.ar/deportes/#/HomePage", text: "Caba"},
			{url: "https://example.com/4", text: "Buenos Aires"},
		],
	},
	{
		trigger    : "seoblackjack",
		title      : "¿Desde dónde querés jugar?",
		sportcta   : (
			<>
				Ver más sobre Fútbol Argentino{" "}
				<span className="btnArrowRight">&rarr;</span>
			</>
		),
		sportctaurl:
			"https://www.bplay.bet.ar/deportes/futbol/eventos/liga-profesional/",
		text       : "",
		links      : [
			{url: "https://m.caba.codere.bet.ar/deportes/#/HomePage", text: "Caba"},
			{url: "https://example.com/4", text: "Buenos Aires"},
		],
	},
	{
		trigger    : "sticky-footer-deportes",
		title      : "¿Desde dónde querés jugar?",
		sportcta   : (
			<>
				Ver más sobre Fútbol Argentino{" "}
				<span className="btnArrowRight">&rarr;</span>
			</>
		),
		sportctaurl:
			"https://www.bplay.bet.ar/deportes/futbol/eventos/liga-profesional/",
		text       : "",
		links      : [
			{url: "https://m.caba.codere.bet.ar/deportes/#/HomePage", text: "Caba"},
			{url: "https://example.com/4", text: "Buenos Aires"},
		],
	},
	{
		trigger    : "sticky-footer-directo",
		title      : "¿Desde dónde querés jugar?",
		sportcta   : (
			<>
				Ver más sobre Fútbol Argentino{" "}
				<span className="btnArrowRight">&rarr;</span>
			</>
		),
		sportctaurl:
			"https://www.bplay.bet.ar/deportes/futbol/eventos/liga-profesional/",
		text       : "",
		links      : [
			{url: "https://m.caba.codere.bet.ar/deportes/#/HomePage", text: "Caba"},
			{url: "https://example.com/4", text: "Buenos Aires"},
		],
	},
	{
		trigger    : "sticky-footer-casino",
		title      : "¿Desde dónde querés jugar?",
		sportcta   : (
			<>
				Ver más sobre Fútbol Argentino{" "}
				<span className="btnArrowRight">&rarr;</span>
			</>
		),
		sportctaurl:
			"https://www.bplay.bet.ar/deportes/futbol/eventos/liga-profesional/",
		text       : "",
		links      : [
			{url: "https://m.caba.codere.bet.ar/deportes/#/HomePage", text: "Caba"},
			{url: "https://example.com/4", text: "Buenos Aires"},
		],
	},

	//     -----------------

	{
		trigger    : "btnBl_1",
		title      : "¿Desde dónde querés jugar?",
		sportcta   : (
			<>
				Ver más sobre Fútbol Argentino{" "}
				<span className="btnArrowRight">&rarr;</span>
			</>
		),
		sportctaurl:
			"https://www.bplay.bet.ar/deportes/futbol/eventos/liga-profesional/",
		text       : "",
		links      : [
			{url: "https://m.caba.codere.bet.ar/deportes/#/HomePage", text: "Caba"},
			{url: "https://example.com/4", text: "Buenos Aires"},
		],
	},
	{
		trigger    : "btnBl_2",
		title      : "¿Desde dónde querés jugar?",
		sportcta   : (
			<>
				Ver más sobre Fútbol Argentino{" "}
				<span className="btnArrowRight">&rarr;</span>
			</>
		),
		sportctaurl:
			"https://www.bplay.bet.ar/deportes/futbol/eventos/liga-profesional/",
		text       : "",
		links      : [
			{url: "https://m.caba.codere.bet.ar/deportes/#/HomePage", text: "Caba"},
			{url: "https://example.com/4", text: "Buenos Aires"},
		],
	},
	{
		trigger    : "btnBl_3",
		title      : "¿Desde dónde querés jugar?",
		sportcta   : (
			<>
				Ver más sobre Fútbol Argentino{" "}
				<span className="btnArrowRight">&rarr;</span>
			</>
		),
		sportctaurl:
			"https://www.bplay.bet.ar/deportes/futbol/eventos/liga-profesional/",
		text       : "",
		links      : [
			{url: "https://m.caba.codere.bet.ar/deportes/#/HomePage", text: "Caba"},
			{url: "https://example.com/4", text: "Buenos Aires"},
		],
	},
	{
		trigger    : "btnBl_4",
		title      : "¿Desde dónde querés jugar?",
		sportcta   : (
			<>
				Ver más sobre Fútbol Argentino{" "}
				<span className="btnArrowRight">&rarr;</span>
			</>
		),
		sportctaurl:
			"https://www.bplay.bet.ar/deportes/futbol/eventos/liga-profesional/",
		text       : "",
		links      : [
			{url: "https://m.caba.codere.bet.ar/deportes/#/HomePage", text: "Caba"},
			{url: "https://example.com/4", text: "Buenos Aires"},
		],
	},

	// footer
	{
		trigger    : "juego-responsable",
		title      : "¿Desde dónde querés jugar?",
		sportcta   : (
			<>
				Ver más sobre Fútbol Argentino{" "}
				<span className="btnArrowRight">&rarr;</span>
			</>
		),
		sportctaurl:
			"https://www.bplay.bet.ar/deportes/futbol/eventos/liga-profesional/",
		text       : "",
		links      : [
			{url: "https://m.caba.codere.bet.ar/deportes/#/HomePage", text: "Caba"},
			{url: "https://example.com/4", text: "Buenos Aires"},
		],
	},

	{
		trigger    : "politica_de_privacidad",
		title      : "¿Desde dónde querés jugar?",
		sportcta   : (
			<>
				Ver más sobre Fútbol Argentino{" "}
				<span className="btnArrowRight">&rarr;</span>
			</>
		),
		sportctaurl:
			"https://www.bplay.bet.ar/deportes/futbol/eventos/liga-profesional/",
		text       : "",
		links      : [
			{url: "https://m.caba.codere.bet.ar/deportes/#/HomePage", text: "Caba"},
			{url: "https://example.com/4", text: "Buenos Aires"},
		],
	},

	{
		trigger    : "reglas",
		title      : "¿Desde dónde querés jugar?",
		sportcta   : (
			<>
				Ver más sobre Fútbol Argentino{" "}
				<span className="btnArrowRight">&rarr;</span>
			</>
		),
		sportctaurl:
			"https://www.bplay.bet.ar/deportes/futbol/eventos/liga-profesional/",
		text       : "",
		links      : [
			{url: "https://m.caba.codere.bet.ar/deportes/#/HomePage", text: "Caba"},
			{url: "https://example.com/4", text: "Buenos Aires"},
		],
	},

	{
		trigger    : "politica_de_privacidad",
		title      : "¿Desde dónde querés jugar?",
		sportcta   : (
			<>
				Ver más sobre Fútbol Argentino{" "}
				<span className="btnArrowRight">&rarr;</span>
			</>
		),
		sportctaurl:
			"https://www.bplay.bet.ar/deportes/futbol/eventos/liga-profesional/",
		text       : "",
		links      : [
			{url: "https://m.caba.codere.bet.ar/deportes/#/HomePage", text: "Caba"},
			{url: "https://example.com/4", text: "Buenos Aires"},
		],
	},


	{
		trigger    : "politica-de-cookies",
		title      : "¿Desde dónde querés jugar?",
		sportcta   : (
			<>
				Ver más sobre Fútbol Argentino{" "}
				<span className="btnArrowRight">&rarr;</span>
			</>
		),
		sportctaurl:
			"https://www.bplay.bet.ar/deportes/futbol/eventos/liga-profesional/",
		text       : "",
		links      : [
			{url: "https://m.caba.codere.bet.ar/deportes/#/HomePage", text: "Caba"},
			{url: "https://example.com/4", text: "Buenos Aires"},
		],
	},

	// LINKS DESTACADOS
	{
		trigger    : "resultados-y-estadisticas",
		title      : "¿Desde dónde querés jugar?",
		sportcta   : (
			<>
				Ver más sobre Fútbol Argentino{" "}
				<span className="btnArrowRight">&rarr;</span>
			</>
		),
		sportctaurl:
			"https://www.bplay.bet.ar/deportes/futbol/eventos/liga-profesional/",
		text       : "",
		links      : [
			{url: "https://m.caba.codere.bet.ar/deportes/#/HomePage", text: "Caba"},
			{url: "https://example.com/4", text: "Buenos Aires"},
		],
	},


	{
		trigger    : "casinoFooterP",
		title      : "¿Desde dónde querés jugar?",
		sportcta   : (
			<>
				Ver más sobre Fútbol Argentino{" "}
				<span className="btnArrowRight">&rarr;</span>
			</>
		),
		sportctaurl:
			"https://www.bplay.bet.ar/deportes/futbol/eventos/liga-profesional/",
		text       : "",
		links      : [
			{url: "https://m.caba.codere.bet.ar/deportes/#/HomePage", text: "Caba"},
			{url: "https://example.com/4", text: "Buenos Aires"},
		],
	},


	{
		trigger    : "casinoblackjackP",
		title      : "¿Desde dónde querés jugar?",
		sportcta   : (
			<>
				Ver más sobre Fútbol Argentino{" "}
				<span className="btnArrowRight">&rarr;</span>
			</>
		),
		sportctaurl:
			"https://www.bplay.bet.ar/deportes/futbol/eventos/liga-profesional/",
		text       : "",
		links      : [
			{url: "https://m.caba.codere.bet.ar/deportes/#/HomePage", text: "Caba"},
			{url: "https://example.com/4", text: "Buenos Aires"},
		],
	},


	{
		trigger    : "casinoslotsP",
		title      : "¿Desde dónde querés jugar?",
		sportcta   : (
			<>
				Ver más sobre Fútbol Argentino{" "}
				<span className="btnArrowRight">&rarr;</span>
			</>
		),
		sportctaurl:
			"https://www.bplay.bet.ar/deportes/futbol/eventos/liga-profesional/",
		text       : "",
		links      : [
			{url: "https://m.caba.codere.bet.ar/deportes/#/HomePage", text: "Caba"},
			{url: "https://example.com/4", text: "Buenos Aires"},
		],
	},


	{
		trigger    : "casinoruletaP",
		title      : "¿Desde dónde querés jugar?",
		sportcta   : (
			<>
				Ver más sobre Fútbol Argentino{" "}
				<span className="btnArrowRight">&rarr;</span>
			</>
		),
		sportctaurl:
			"https://www.bplay.bet.ar/deportes/futbol/eventos/liga-profesional/",
		text       : "",
		links      : [
			{url: "https://m.caba.codere.bet.ar/deportes/#/HomePage", text: "Caba"},
			{url: "https://example.com/4", text: "Buenos Aires"},
		],
	},
	// DEPORTES
	{
		trigger    : "deposito-online",
		title      : "¿Desde dónde querés jugar?",
		sportcta   : (
			<>
				Ver más sobre Fútbol Argentino{" "}
				<span className="btnArrowRight">&rarr;</span>
			</>
		),
		sportctaurl:
			"https://www.bplay.bet.ar/deportes/futbol/eventos/liga-profesional/",
		text       : "",
		links      : [
			{url: "https://m.caba.codere.bet.ar/deportes/#/HomePage", text: "Caba"},
			{url: "https://example.com/4", text: "Buenos Aires"},
		],
	},

	{
		trigger    : "como-y-donde-apostar",
		title      : "¿Desde dónde querés jugar?",
		sportcta   : (
			<>
				Ver más sobre Fútbol Argentino{" "}
				<span className="btnArrowRight">&rarr;</span>
			</>
		),
		sportctaurl:
			"https://www.bplay.bet.ar/deportes/futbol/eventos/liga-profesional/",
		text       : "",
		links      : [
			{url: "https://m.caba.codere.bet.ar/deportes/#/HomePage", text: "Caba"},
			{url: "https://example.com/4", text: "Buenos Aires"},
		],
	},
	// AYUDA

	{
		trigger    : "terminos-y-condiciones-ayuda",
		title      : "¿Desde dónde querés jugar?",
		sportcta   : (
			<>
				Ver más sobre Fútbol Argentino{" "}
				<span className="btnArrowRight">&rarr;</span>
			</>
		),
		sportctaurl:
			"https://www.bplay.bet.ar/deportes/futbol/eventos/liga-profesional/",
		text       : "",
		links      : [
			{url: "https://m.caba.codere.bet.ar/deportes/#/HomePage", text: "Caba"},
			{url: "https://example.com/4", text: "Buenos Aires"},
		],
	},



	/*end */
];
